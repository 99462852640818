<template>
	<div id="thread-container" class="move-left">

        <transition name="slide">
            <router-view></router-view>
        </transition>

		<phi-page :loading="isLoading" color="#f3f3f3" class="state-calendar">
			<div slot="toolbar">
				<h1>{{ title }}</h1>
				<a :href="`${this.$store.state.api.host}/people/${this.$store.state.user.id}/calendar/ics?h=${hash}`"><i class="fa fa-calendar-plus-o"></i></a>
			</div>

			<!-- <div style="height: 100%">  Stupid iOS will set the height to 2px in this scenario -->
			<div style="height: 600px">
				<div class="views-list-menu" v-show="displayViewsMenu">
					<ul class="phi-menu">
						<li @click="currentView = 'month'" 		v-bind:class="{ 'phi-menu-selected': currentView === 'month' }">{{ $t("noun.month") }}</li>
						<li @click="currentView = 'agendaDay'" 	v-bind:class="{ 'phi-menu-selected': currentView === 'agendaDay' }">{{ $t("noun.day") }}</li>
						<li @click="currentView = 'agendaWeek'" v-bind:class="{ 'phi-menu-selected': currentView === 'agendaWeek' }">{{ $t("noun.week") }}</li>
						<li @click="currentView = 'listMonth'"	v-bind:class="{ 'phi-menu-selected': currentView === 'listMonth' }">{{ $t("noun.agenda") }}</li>
					</ul>
				</div>

				<div class="sources-filter-menu" v-show="displaySourcesFilter">
					<ul class="phi-menu short-padding">
						<li class="prevent-menu-hiding"
							@click="sourceDef.toggle()"
							v-for="sourceDef in eventSources"
						>
							<span class="list-square" v-bind:style="{color: sourceDef.color}">
								<mu-icon v-if="sourceDef.enabled" value="check_box"/>
								<mu-icon v-else value="check_box_outline_blank"/>
							</span>
							{{sourceDef.title}}
						</li>
					</ul>
				</div>

				<full-calendar
					:options="myCalendar"
					:current-view="currentView"
					@created="setCalendar"
				></full-calendar>

				<mu-dialog :open="eventDetails.open" :title="eventDetails.title">
					{{ eventDetails.date }}
					<br>
					{{ eventDetails.description }}
					<mu-flat-button :label="eventDetails.buttonLabel" slot="actions" primary @click="eventDetails.open = false"/>
				</mu-dialog>
			</div>

			<div class="day-picker hidden-block"><date-picker @input="datePicked"></date-picker></div>
		</phi-page>
	</div>
</template>

<script>
import md5 from 'blueimp-md5';
//import FullCalendar from '@/components/Fullcalendar.vue';
//import FCUtils from '@/libraries/phidias.js/lib/Fullcalendar.js';
import DatePicker from '@/components/DatePicker.vue';

export default {
	name: "calendar",
    components: {DatePicker},

	data () {
		return {
			isLoading: false,
			displayViewsMenu: false,
			displaySourcesFilter: false,
			//fullCalendarInstance: null, //fullcalendar object returned on creation
			myCalendar: {},
			eventSources: [],
			hash: md5(`cal${this.$store.state.user.id}shh`),
			title: null,
            eventDetails: {
                open: false,
                title: "",
                date: "",
                description: "",
                buttonLabel: this.$t('action.accept')
            },
            datePicker: null
		}
	},

    computed: {
        currentView: {
            get: function () {
                return this.$store.state.stateCalendar.currentView;
            },

            set: function (newView) {
                this.$store.commit('setCalendarView', newView);
            }
        }
	},

	methods: {
		initializeSources() {
			let calendarConfig = {
				googleCalendarApiKey: 'AIzaSyC-w7WOjp6Rgg4pZZDFW7Eqp6gR2AAlt4I',
				locale: this.$store.state.i18n.language.slice(0,2),
        		firstDay: 0,
				defaultView: this.currentView,
				height: 'parent',
                noEventsMessage: this.$t("notice.thereIsNothingHere"),
				header: {
					left: 'viewSwitcher,sourcesFilter',
					center: '',
					right: 'today,prev,next'
				},
				loading: (isLoading, view) => {
					this.isLoading = isLoading;

					if (!isLoading) {
						// this.fullCalendarInstance.fullCalendar('option', 'noEventsMessage', this.$t("notice.thereIsNothingHere"));
					} else {
						// this.fullCalendarInstance.fullCalendar('option', 'noEventsMessage', this.$t("notice.loading")+'...');
					}
				},
				customButtons: {
					viewSwitcher: {
						text: this.$t("noun.view"),
						click: evt => {
							evt.stopPropagation();
							this.displaySourcesFilter = false;
							this.displayViewsMenu = !this.displayViewsMenu;
						}
					},

					sourcesFilter: {
						text: this.$t("noun.type"),
						click: evt => {
							evt.stopPropagation();
							this.displayViewsMenu =  false;
							this.displaySourcesFilter = !this.displaySourcesFilter;
						}
					}
				},
				viewRender: (view, element) => {
					this.positionMenu("button.fc-viewSwitcher-button", "div.views-list-menu");
					this.positionMenu("button.fc-sourcesFilter-button", "div.sources-filter-menu");

					this.title = view.title;

                    this.injectDatePicker();
				},
				eventClick: (calEvent, jsEvent, view) => {
					jsEvent.preventDefault();

					if (calEvent.source.googleCalendarId) {
                        if (calEvent.url) {
                            let googleWindow = window.open(calEvent.url, '_system');
                            googleWindow.focus();
                        }
					} else {
						if (calEvent.hasOwnProperty('post')) {
							this.$router.push({ name: 'calendar-thread', params: { threadId: calEvent.post.thread2 }});
						} else {
                            if (calEvent.url) {
                                let newWindow = window.open(calEvent.url, '_system');
                                newWindow.focus();
                            } else {

                            	if ( typeof calEvent.start._i != "undefined" ) {
								    calEvent.start = new Date(calEvent.start._i);
								}

                            	this.eventDetails.title = calEvent.source.title;
                                this.eventDetails.date = this.$date(calEvent.start);
                                this.eventDetails.description = calEvent.title;
                                this.eventDetails.open = true;
                            }
						}
					}
				},
                eventDataTransform: eventData => {
                    if (!eventData.title) {
                        eventData.title = this.$t("noun.busy");
                    }

                    if (!eventData.description) {
                        eventData.description = "";
                    }

                    return eventData;
                }
			};

			//main sources array
			let sources = [];

			//get post types and create feeds for every one of them
			let calendarsByType = this.$store.state.api.collection(`people/${this.$store.state.user.id}/threads/types`, {hasEvent: 1})
				.fetch()
				.then( types => {
					types.forEach( (typeDef, index) => {
						sources.push({
							title: typeDef.plural,
							url: `${this.$store.state.api.host}/people/${this.$store.state.user.id}/calendar/feed?type=${typeDef.singular}`,
							headers: {
								Accept: 'application/json+fullcalendar',
								Authorization: `Bearer ${this.$store.state.api.token}`
							}
						});
					});
				});

            //get post types and create feeds for v3 linked google calendars
			let v3Calendars = this.$store.state.api.collection(`v3/people/${this.$store.state.user.id}/calendar/feed`)
				.fetch()
				.then( calendars => {
					calendars.forEach( (calendarDef, index) => {
						sources.push({
                            enabled: true,
							title: calendarDef.name,
							googleCalendarId: calendarDef.id
						});
					});
				});

            let v3AcademicPeople = this.$store.state.api.get(`v3/people/${this.$store.state.user.id}/academic/calendar/sources`, {withpermissions: 1})
                .then( response => {
                    response.sources.forEach( (person, index) => {

                        let academicTypes = ["assignments", "exams", "evaluations"];
                        let sourceTitle = this.$t("events");

                        academicTypes.forEach( (academicType, index) => {

                            if (response.permissions[academicType]) {
                                sourceTitle = this.$t(academicType);
                                if (person.id != this.$store.state.user.id) {
                                    sourceTitle = sourceTitle + " (" + person.name + ")";
                                }

                                sources.push({
                                    title: sourceTitle,
                                    url: `${this.$store.state.api.host}/v3/people/${person.id}/academic/${academicType}/feed`,
                                    headers: {
                                        Authorization: `Bearer ${this.$store.state.api.token}`
                                    }
                                });
                            }

                        });
                    });
                });

			//wait for everything to finish, then configure the calendar
			Promise.all([calendarsByType, v3Calendars, v3AcademicPeople]).then( () => {
				this.preparedSources = FCUtils.prepareSources(sources);
				this.myCalendar = calendarConfig;
			});

		},

		setCalendar(fullCalendarInstance) {
            // this.fullCalendarInstance = fullCalendarInstance;
            // this.eventSources = FCUtils.injectSources(this.fullCalendarInstance, this.preparedSources);
		},

		positionMenu (anchor, menuElement) {
			let viewChangerButton = this.$el.querySelector(anchor);
            if (viewChangerButton) {
                let viewChangerMenu = this.$el.querySelector(menuElement);
                let buttonHeight = parseInt(window.getComputedStyle(viewChangerButton).getPropertyValue('height'));

                let xPos = 0;
                let yPos = 0;

                xPos += (viewChangerButton.getBoundingClientRect().left - viewChangerButton.scrollLeft + viewChangerButton.clientLeft);
                yPos += (viewChangerButton.getBoundingClientRect().top - viewChangerButton.scrollTop + viewChangerButton.clientTop);

                viewChangerMenu.style.left = xPos + "px";
                viewChangerMenu.style.top = yPos + buttonHeight + 3 + "px";
            }
		},

		addMenuEvents () {
			window.addEventListener("resize", () => {
				this.positionMenu("button.fc-viewSwitcher-button", "div.views-list-menu");
				this.positionMenu("button.fc-sourcesFilter-button", "div.sources-filter-menu");
			});

			document.body.addEventListener("click", evt => {
				if (evt.target.classList.contains("prevent-menu-hiding")) {
					evt.stopPropagation();
					return false;
				}

				if (this.displayViewsMenu) {
					this.displayViewsMenu = false;
				}

				if (this.displaySourcesFilter) {
					this.displaySourcesFilter = false;
				}
			});

			document.addEventListener('scroll', evt =>  {
				this.positionMenu("button.fc-viewSwitcher-button", "div.views-list-menu");
				this.positionMenu("button.fc-sourcesFilter-button", "div.sources-filter-menu");
			}, true);

			this.$parent.$on("transitionFinished", () => {
				this.positionMenu("button.fc-viewSwitcher-button", "div.views-list-menu");
				this.positionMenu("button.fc-sourcesFilter-button", "div.sources-filter-menu");
			});
		},

        injectDatePicker(){
            let centerToolbar = this.$el.querySelector("div.fc-toolbar.fc-header-toolbar > div.fc-center");

            centerToolbar.appendChild(this.datePicker);
            this.datePicker.classList.remove("hidden-block");
        },

        datePicked(date){
            let newDate = new Date();
            newDate.setTime(date*1000);

            let dateString = newDate.toUTCString();

            // this.fullCalendarInstance.fullCalendar( 'gotoDate', dateString );
        }
	},

	mounted() {
        this.initializeSources();
		this.addMenuEvents();

        this.datePicker = this.$el.querySelector(".day-picker");
	}
}
</script>

<style lang="scss">
.state-calendar {
    .hidden-block{
        visibility: hidden;
        display: none;
    }

    .date-picker{
        min-width: 24px;
        border-bottom: none;

        .date-picker-face span{
            visibility: hidden;
            display: none;
        }

        .date-picker-face i{
            padding: 4px;
            border: 1px solid #B8B8B8;
            border-radius: 4px;
        }
    }

	div.fc-toolbarss.fc-header-toolbar > div.fc-center {

	}

	.phi-menu-selected {
		background-color: rgba(0, 0, 0, 0.1);
		font-weight: bold;
	}

	.phi-menu-selected-alt {
		background-color: rgb(238, 238, 238);
		border-left: 5px solid #303e4d;
	}

	.phi-menu.short-padding li {
		padding-left: 10px;
	}

	.phi-menu.short-padding li:first-child {
		border-top-left-radius:3px;
	}

	.phi-menu.short-padding li:last-child {
		border-bottom-left-radius:3px;
	}

	.views-list-menu {
		width:200px;
		position: fixed;
		background-color: #eee;
		border: 1px solid #ccc;
		border-radius: 3px;
		z-index: 2;
	}

	.sources-filter-menu {
		width:200px;
		position: fixed;
		background-color: #eee;
		border: 1px solid #ccc;
		border-radius: 3px;
		z-index: 2;

		max-height: 65vh;
		overflow: hidden;
		overflow-y: auto;
	}

	.list-square {
		font-size: 16px;
	}
}


#thread-container {
    height: 100%;

    .phi-page {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
    }

    #thread {
        z-index: 1;
    }
}
</style>