<template>
    <div :class="{'date-picker': true, open: isOpen && !isMobile, mobile: isMobile}">
        <div class="date-picker-face" @click="toggle()">
            <span class="current-date" v-if="value">{{ $date(value, "day") }} <span v-if="time">{{ $date(value, "time") }}</span></span>
            <span class="current-date placeholder" v-else>{{ placeholder }}</span>
            <phi-icon value="g:today" />
        </div>

        <div class="date-picker-popup">

            <input ref="mainInput" class="main-input" type="text" />

            <footer>
                <button type="button" @click="save()" v-if="time">{{ $t("DatePicker.OK") }}</button>
                <button type="button" @click="setToday()">{{ $t("DatePicker.Today") }}</button>
                <button type="button" @click="clear()">{{ $t("DatePicker.None") }}</button>
            </footer>
        </div>
    </div>
</template>

<script>
import 'flatpickr/dist/flatpickr.min.css';
import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'flatpickr';

import PhiIcon from '@/components/Phi/Icon/Icon.vue'

export default {
    name: "date-picker",

    components: {
        PhiIcon
    },

    props: {
        value: {},
        time: {},
        placeholder: {}
    },

    data() {
        return {
            date: null,
            instance: null,
            config: {},
            isOpen: false,

            clickListener: event => {
                if (!this.$el.contains(event.target)) {
                    // Clicked outside
                    this.close();
                }
            }
        };
    },

    computed: {
        isMobile() {
            let check = false;
            // eslint-disable-next-line no-useless-escape
            (function (a) {
                if (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                    a
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                    a.substr(0, 4)
                )
                )
                check = true;
            })(navigator.userAgent || navigator.vendor || window.opera);
            return check;
        }
    },

    watch: {
        time(newValue){
            let a = Object.assign({}, this.instance.config);
            this.instance.destroy();
            a.enableTime = newValue;
            this.instance = Flatpickr(this.$refs.mainInput,a);

        },
        value(newValue) {
            if (typeof newValue == "object") {
                this.date = newValue;
            } else {
                this.date = newValue ? new Date(parseInt(newValue)*1000) : null;
            }

            this.instance.setDate(this.date);
        }
    },

    mounted() {
        this.date = this.value ? new Date(parseInt(this.value)*1000) : null;

        this.instance = Flatpickr(this.$refs.mainInput, {
            enableTime: this.time,
            time_24hr: this.$store && this.$store.getters ? !this.$store.getters.usesAmPm : true,
            inline: !this.isMobile,
            onChange: dates => this.isMobile || !this.time ? this.save() : null // si no hay selector de tiempo, guardar al seleccionar un dia
        });

        this.instance.setDate(this.date);
    },

    methods: {
        open() {
            if (this.isMobile) {
                console.log("isMobile: open");
                

                this.instance.open();
                return;
            }

            this.isOpen = true;
            window.addEventListener('click', this.clickListener);
        },

        close() {
            this.isOpen = false;
            this.instance.setDate(this.date);
            window.removeEventListener('click', this.clickListener);
        },

        toggle() {
            this.isOpen ? this.close() : this.open();
        },

        save() {
            let value = this.instance.selectedDates.length ? this.instance.selectedDates[0].getTime()/1000 : null;
            this.$emit('input', value);
            this.close();
        },

        setToday() {
            this.date = new Date();
            this.instance.setDate(this.date);
            if (!this.time) {
                this.$emit('input', Math.floor(this.date.getTime()/1000));
                this.close();
            }
        },

        clear() {
            this.date = null;
            this.$emit('input', null);
            this.close();
        }
    },

    i18n: {
        "en": {
            "DatePicker.OK": "OK",
            "DatePicker.Today": "Today",
            "DatePicker.None": "$t(noun.none)"
        },

        "de": {
            "DatePicker.Today": "Heute"
        },

        "es": {
            "DatePicker.Today": "Hoy"
        },

        "fr": {
            "DatePicker.Today": "Aujourd'hui"
        },

        "it": {
            "DatePicker.Today": "Oggi"
        }
    }
}
</script>

<style lang="scss">
.date-picker {
    display: inline-block;
    min-width: 160px;
    border-bottom: 1px solid #ddd;

    .date-picker-face {
        cursor: pointer;
        display: flex;
        align-items: center;

        span {
            flex: 1;
            white-space: nowrap;
        }

        .phi-icon {
            color: #999;
        }

        .placeholder {
            opacity: .7;
        }
    }

    .date-picker-popup {
        position: absolute;
        z-index: 2;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 1px 0 0 #eee, -1px 0 0 #eee, 0 1px 0 #eee, 0 -1px 0 #eee, 0 3px 13px rgba(0,0,0,0.1);

        display: none;

        footer {
            text-align: center;
            padding: 20px 12px;
            button {
                margin: 0 1em;
            }
        }

        .main-input {
            // display: none;
            z-index: -1;
            position: absolute;
            top: -2000px;
            left: -2000px;
        }

        .flatpickr-calendar {
            box-shadow: none;
        }
    }

    &.open {
        .date-picker-popup {
            display: block;
        }
    }

    &.mobile {
        .date-picker-popup {
            display: block;
            visibility: hidden;
        }
    }
}
</style>